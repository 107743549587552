import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Navigation } from '../../components/Navigation';
import { supabase } from '../../supabaseClient';
import toast, { Toaster } from 'react-hot-toast';
import '../../styles/content.css';

interface ContentItem {
  id: string;
  title: string;
  description: string;
  content_type: 'text' | 'audio' | 'meeting_transcript' | 'interview' | 'document';
  status: 'pending' | 'processing' | 'ready' | 'error';
  original_content?: string;
  audio_url?: string;
  tags: string[];
  created_at: string;
  word_count?: number;
  duration?: number;
  is_favorite: boolean;
}

export function Content() {
  const { user } = useAuth();
  const [contents, setContents] = useState<ContentItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploadType, setUploadType] = useState<'text' | 'audio' | null>(null);
  const [newContent, setNewContent] = useState({
    title: '',
    description: '',
    content: '',
    tags: '',
  });
  const [filter, setFilter] = useState({
    type: 'all',
    search: '',
    sort: 'newest'
  });

  // Load content
  useEffect(() => {
    loadContent();
  }, [user?.id]);

  const loadContent = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('content')
        .select('*')
        .eq('user_id', user?.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setContents(data || []);
    } catch (err) {
      console.error('Error loading content:', err);
      toast.error('Failed to load content');
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.id) return;

    try {
      setLoading(true);
      const contentData = {
        user_id: user.id,
        title: newContent.title,
        description: newContent.description,
        content_type: uploadType,
        original_content: uploadType === 'text' ? newContent.content : null,
        tags: newContent.tags.split(',').map(tag => tag.trim()),
        status: 'pending',
        word_count: uploadType === 'text' ? newContent.content.split(/\s+/).length : null
      };

      const { error } = await supabase
        .from('content')
        .insert(contentData)
        .single();

      if (error) throw error;

      toast.success('Content uploaded successfully');
      setNewContent({ title: '', description: '', content: '', tags: '' });
      setUploadType(null);
      loadContent();
    } catch (err) {
      console.error('Error uploading content:', err);
      toast.error('Failed to upload content');
    } finally {
      setLoading(false);
    }
  };

  const handleAudioUpload = async (file: File) => {
    if (!user?.id) return;

    try {
      setLoading(true);
      const fileName = `${user.id}/${Date.now()}_${file.name}`;
      
      // Upload to Supabase Storage
      const { error: uploadError, data } = await supabase.storage
        .from('audio-uploads')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      // Create content record
      const contentData = {
        user_id: user.id,
        title: newContent.title || file.name,
        description: newContent.description,
        content_type: 'audio',
        audio_url: data?.path,
        tags: newContent.tags.split(',').map(tag => tag.trim()),
        status: 'pending',
        file_size: file.size
      };

      const { error } = await supabase
        .from('content')
        .insert(contentData)
        .single();

      if (error) throw error;

      toast.success('Audio uploaded successfully');
      setNewContent({ title: '', description: '', content: '', tags: '' });
      setUploadType(null);
      loadContent();
    } catch (err) {
      console.error('Error uploading audio:', err);
      toast.error('Failed to upload audio');
    } finally {
      setLoading(false);
    }
  };

  const filteredContent = contents.filter(content => {
    if (filter.type !== 'all' && content.content_type !== filter.type) return false;
    if (filter.search) {
      const searchLower = filter.search.toLowerCase();
      return (
        content.title.toLowerCase().includes(searchLower) ||
        content.description.toLowerCase().includes(searchLower) ||
        content.tags.some(tag => tag.toLowerCase().includes(searchLower))
      );
    }
    return true;
  });

  return (
    <div className="dashboard-layout">
      <Navigation />
      <div className="content-container">
        <header className="content-header">
          <h1>Content Library</h1>
          <div className="content-actions">
            <button onClick={() => setUploadType('text')} className="upload-button">
              Add Text Content
            </button>
            <button onClick={() => setUploadType('audio')} className="upload-button">
              Upload Audio
            </button>
          </div>
        </header>

        {uploadType && (
          <div className="upload-form">
            <h2>{uploadType === 'text' ? 'Add Text Content' : 'Upload Audio'}</h2>
            <form onSubmit={handleUpload}>
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  value={newContent.title}
                  onChange={e => setNewContent(prev => ({ ...prev, title: e.target.value }))}
                  required
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  value={newContent.description}
                  onChange={e => setNewContent(prev => ({ ...prev, description: e.target.value }))}
                />
              </div>
              {uploadType === 'text' ? (
                <div className="form-group">
                  <label>Content</label>
                  <textarea
                    value={newContent.content}
                    onChange={e => setNewContent(prev => ({ ...prev, content: e.target.value }))}
                    required
                    rows={10}
                  />
                </div>
              ) : (
                <div className="form-group">
                  <label>Audio File</label>
                  <input
                    type="file"
                    accept="audio/*"
                    onChange={e => e.target.files?.[0] && handleAudioUpload(e.target.files[0])}
                    required
                  />
                </div>
              )}
              <div className="form-group">
                <label>Tags (comma-separated)</label>
                <input
                  type="text"
                  value={newContent.tags}
                  onChange={e => setNewContent(prev => ({ ...prev, tags: e.target.value }))}
                  placeholder="meeting, interview, notes..."
                />
              </div>
              <div className="form-actions">
                <button type="submit" disabled={loading}>
                  {loading ? 'Uploading...' : 'Upload'}
                </button>
                <button type="button" onClick={() => setUploadType(null)}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        )}

        <div className="content-filters">
          <input
            type="text"
            placeholder="Search content..."
            value={filter.search}
            onChange={e => setFilter(prev => ({ ...prev, search: e.target.value }))}
            className="search-input"
          />
          <select
            value={filter.type}
            onChange={e => setFilter(prev => ({ ...prev, type: e.target.value }))}
            className="filter-select"
          >
            <option value="all">All Types</option>
            <option value="text">Text</option>
            <option value="audio">Audio</option>
            <option value="meeting_transcript">Meeting Transcript</option>
            <option value="interview">Interview</option>
            <option value="document">Document</option>
          </select>
          <select
            value={filter.sort}
            onChange={e => setFilter(prev => ({ ...prev, sort: e.target.value }))}
            className="filter-select"
          >
            <option value="newest">Newest First</option>
            <option value="oldest">Oldest First</option>
            <option value="title">Title A-Z</option>
          </select>
        </div>

        <div className="content-grid">
          {loading ? (
            <div className="loading">Loading content...</div>
          ) : filteredContent.length === 0 ? (
            <div className="no-content">
              No content found. Start by adding some text or uploading audio!
            </div>
          ) : (
            filteredContent.map(content => (
              <div key={content.id} className="content-card">
                <div className="content-type-badge">{content.content_type}</div>
                <h3>{content.title}</h3>
                <p>{content.description}</p>
                <div className="content-meta">
                  {content.word_count && <span>{content.word_count} words</span>}
                  {content.duration && <span>{Math.floor(content.duration / 60)}:{(content.duration % 60).toString().padStart(2, '0')}</span>}
                  <span>{new Date(content.created_at).toLocaleDateString()}</span>
                </div>
                <div className="content-tags">
                  {content.tags.map(tag => (
                    <span key={tag} className="tag">{tag}</span>
                  ))}
                </div>
                <div className="content-actions">
                  <button className="action-button">
                    <span className="icon">▶️</span> Play
                  </button>
                  <button className="action-button">
                    <span className="icon">📝</span> Edit
                  </button>
                  <button className="action-button favorite">
                    <span className="icon">{content.is_favorite ? '⭐' : '☆'}</span>
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <Toaster position="top-right" />
    </div>
  );
}
