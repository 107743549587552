import { Link } from 'react-router-dom';
import { useState } from 'react';
import { HamburgerIcon } from '../../components/HamburgerIcon';
import { MobileMenu } from '../../components/MobileMenu';
import '../../styles/home.css';

export function Home() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="home-container">
      <nav className="home-nav">
        <div className="nav-brand">VoiceFlow</div>
        <div className="nav-actions desktop-only">
          <Link to="/signin" className="nav-link">Sign In</Link>
          <Link to="/start-for-free" className="nav-button">Get Started</Link>
        </div>
        <div className="mobile-only">
          <HamburgerIcon 
            isOpen={isMenuOpen} 
            onClick={() => setIsMenuOpen(!isMenuOpen)} 
          />
        </div>
      </nav>

      <MobileMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />

      <main className="hero-section">
        <div className="hero-content">
          <h1>Transform Your Voice Into Action</h1>
          <p className="hero-subtitle">
            Record, transcribe, and analyze your voice with powerful AI tools. 
            Perfect for podcasters, content creators, and professionals.
          </p>
          <div className="hero-actions">
            <Link to="/start-for-free" className="cta-button">Start Free Trial</Link>
            <a href="#features" className="learn-more">
              Learn More
              <span className="arrow">↓</span>
            </a>
          </div>
        </div>
      </main>

      <section id="features" className="features-section">
        <h2>Why Choose VoiceFlow?</h2>
        <div className="features-grid">
          <div className="feature-card">
            <div className="feature-icon">🎙️</div>
            <h3>Crystal Clear Recording</h3>
            <p>Professional-grade voice recording with noise reduction and audio enhancement.</p>
          </div>
          <div className="feature-card">
            <div className="feature-icon">🤖</div>
            <h3>AI-Powered Analysis</h3>
            <p>Get instant insights and analytics about your voice recordings.</p>
          </div>
          <div className="feature-card">
            <div className="feature-icon">📝</div>
            <h3>Smart Transcription</h3>
            <p>Accurate, real-time transcription in multiple languages.</p>
          </div>
          <div className="feature-card">
            <div className="feature-icon">🔒</div>
            <h3>Secure Storage</h3>
            <p>Your recordings are encrypted and safely stored in the cloud.</p>
          </div>
        </div>
      </section>

      <footer className="home-footer">
        <div className="footer-content">
          <div className="footer-brand">
            <h3>VoiceFlow</h3>
            <p>Transform your voice into action</p>
          </div>
          <div className="footer-links">
            <div className="footer-section">
              <h4>Product</h4>
              <a href="#features">Features</a>
              <a href="#pricing">Pricing</a>
              <Link to="/signup">Sign Up</Link>
            </div>
            <div className="footer-section">
              <h4>Company</h4>
              <a href="#about">About</a>
              <a href="#blog">Blog</a>
              <a href="#contact">Contact</a>
            </div>
            <div className="footer-section">
              <h4>Legal</h4>
              <a href="#privacy">Privacy</a>
              <a href="#terms">Terms</a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 VoiceFlow. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}
