import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';

export function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { user, session } = useAuth();
  const [isValidating, setIsValidating] = useState(true);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const validateSession = async () => {
      try {
        // Check if we have both user and session
        if (!user || !session) {
          setIsValid(false);
          setIsValidating(false);
          return;
        }

        // Verify session with Supabase
        const { data: { session: currentSession }, error } = await supabase.auth.getSession();
        
        if (error || !currentSession) {
          console.error('Session validation failed:', error?.message);
          setIsValid(false);
          setIsValidating(false);
          return;
        }

        // Additional validation: check if the session token matches
        if (currentSession.access_token !== session.access_token) {
          console.error('Session token mismatch');
          setIsValid(false);
          setIsValidating(false);
          return;
        }

        setIsValid(true);
      } catch (error) {
        console.error('Error validating session:', error);
        setIsValid(false);
      } finally {
        setIsValidating(false);
      }
    };

    validateSession();
  }, [user, session]);

  if (isValidating) {
    return <div>Loading...</div>;
  }

  if (!isValid) {
    // Clear any lingering auth data
    localStorage.removeItem('supabase.auth.token');
    return <Navigate to="/signin" />;
  }

  return <>{children}</>;
}
