import { useAuth } from '../../contexts/AuthContext';
import { Navigation } from '../../components/Navigation';
import VoiceRecorder from '../../components/VoiceRecorder';
import '../../styles/dashboard.css';
import '../../styles/VoiceRecorder.css';

export function Dashboard() {
  const { user } = useAuth();

  return (
    <div className="dashboard-layout">
      <Navigation userEmail={user?.email} />
      <div className="dashboard-container">
        <header className="dashboard-header">
          <h1>Welcome back!</h1>
          <div className="user-info desktop-only">
            {user?.email}
          </div>
        </header>
        <main className="dashboard-content">
          <div className="voice-recorder-section">
            <VoiceRecorder />
          </div>
          <div className="recording-stats mobile-stats">
            <div className="stat-item">
              <span className="stat-label">Total Recordings</span>
              <span className="stat-value">4</span>
            </div>
            <div className="stat-item">
              <span className="stat-label">Recording Time</span>
              <span className="stat-value">0:00</span>
            </div>
            <div className="stat-item">
              <span className="stat-label">Latest Recording</span>
              <span className="stat-value">11/29/2024, 9:21:48 PM</span>
            </div>
          </div>
          <div className="desktop-only">
            <div className="getting-started-card">
              <h2>🚀 Getting Started</h2>
              <p>Welcome to your VoiceFlow dashboard! Let's help you get started with some key features.</p>
              <ul>
                <li>Create your first voice recording</li>
                <li>Set up your profile preferences</li>
                <li>Explore voice analytics dashboard</li>
              </ul>
            </div>
            <div className="getting-started-card">
              <h2>📊 Quick Stats</h2>
              <p>Track your voice recording metrics and analytics at a glance.</p>
              <ul>
                <li>Total recordings: 0</li>
                <li>Recording time: 0 minutes</li>
                <li>Storage used: 0 MB</li>
              </ul>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
