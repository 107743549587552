import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/mobile-menu.css';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export function MobileMenu({ isOpen, onClose }: MobileMenuProps) {
  // Lock body scroll when menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  // Handle escape key
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOpen) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <>
      <div className="mobile-menu-backdrop" onClick={onClose} />
      <div 
        className={`mobile-menu ${isOpen ? 'open' : ''}`}
        role="dialog"
        aria-modal="true"
        aria-label="Navigation menu"
      >
        <div className="mobile-menu-content">
          <nav className="mobile-nav">
            <Link to="/signin" className="mobile-nav-link" onClick={onClose}>
              Sign In
            </Link>
            <Link to="/start-for-free" className="mobile-nav-link highlight" onClick={onClose}>
              Get Started
            </Link>
            <a href="#features" className="mobile-nav-link" onClick={onClose}>
              Features
            </a>
            <a href="#about" className="mobile-nav-link" onClick={onClose}>
              About
            </a>
          </nav>
        </div>
      </div>
    </>
  );
}
