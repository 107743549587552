import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';

export function AuthCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const hashParams = new URLSearchParams(window.location.hash.substring(1));
        const type = hashParams.get('type');
        
        if (type === 'recovery') {
          navigate('/reset-password');
          return;
        }

        const { data, error } = await supabase.auth.getSession();
        if (error) throw error;
        if (data?.session) {
          navigate('/dashboard');
        } else {
          navigate('/signin');
        }
      } catch (error) {
        console.error('Error during auth callback:', error);
        navigate('/signin');
      }
    };

    handleCallback();
  }, [navigate]);

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh' 
    }}>
      <p>Processing authentication...</p>
    </div>
  );
}
