import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import '../styles/navigation.css';
import { useState } from 'react';

interface NavigationProps {
  userEmail?: string;
}

export function Navigation({ userEmail }: NavigationProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, session, signOut } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Don't render navigation if user is not logged in
  if (!user || !session) return null;

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Failed to sign out:', error);
      window.location.href = '/signin';
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <button className="mobile-menu-button" onClick={toggleMenu} aria-label="Toggle menu">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6H20M4 12H20M4 18H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
        </svg>
      </button>
      
      <nav className={`nav-container ${isMenuOpen ? 'nav-open' : ''}`}>
        <div className="nav-header">
          <h1>VoiceFlow</h1>
          <button className="close-menu-button" onClick={closeMenu} aria-label="Close menu">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 18L18 6M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </button>
        </div>

        <div className="mobile-user-info">
          <span className="user-email">{userEmail}</span>
        </div>
        
        <div className="nav-links">
          <Link
            to="/dashboard"
            className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`}
            onClick={closeMenu}
          >
            📊 Dashboard
          </Link>
          <Link
            to="/content"
            className={`nav-link ${location.pathname === '/content' ? 'active' : ''}`}
            onClick={closeMenu}
          >
            📚 Content Library
          </Link>
          <Link
            to="/profile"
            className={`nav-link ${location.pathname === '/profile' ? 'active' : ''}`}
            onClick={closeMenu}
          >
            👤 Profile Settings
          </Link>
          <button className="sign-out-button" onClick={handleSignOut}>
            🚪 Sign Out
          </button>
        </div>

      </nav>
      
      {isMenuOpen && <div className="nav-overlay" onClick={closeMenu} />}
    </>
  );
}
