import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AuthRedirect } from './components/AuthRedirect';
import { Auth } from './pages/Auth/Auth';
import { AuthCallback } from './pages/Auth/AuthCallback';
import { ResetPassword } from './pages/Auth/ResetPassword';
import { StartForFree } from './pages/Auth/StartForFree';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Profile } from './pages/Profile/Profile';
import { Content } from './pages/Content/Content';
import { Home } from './pages/Home/Home';
import './styles/auth.css';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {/* Public routes that redirect to dashboard if logged in */}
          <Route
            path="/"
            element={
              <AuthRedirect redirectTo="/dashboard">
                <Home />
              </AuthRedirect>
            }
          />
          <Route
            path="/signin"
            element={
              <AuthRedirect redirectTo="/dashboard">
                <Auth />
              </AuthRedirect>
            }
          />
          <Route
            path="/signup"
            element={
              <AuthRedirect redirectTo="/dashboard">
                <Auth />
              </AuthRedirect>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <AuthRedirect redirectTo="/dashboard">
                <Auth />
              </AuthRedirect>
            }
          />
          <Route
            path="/start-for-free"
            element={
              <AuthRedirect redirectTo="/dashboard">
                <StartForFree />
              </AuthRedirect>
            }
          />
          
          {/* Auth callback and reset password routes */}
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* Protected routes that require authentication */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/content"
            element={
              <ProtectedRoute>
                <Content />
              </ProtectedRoute>
            }
          />

          {/* Catch all route - redirect to home or dashboard */}
          <Route 
            path="*" 
            element={<Navigate to="/" replace />} 
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
