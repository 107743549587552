import { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Navigation } from '../../components/Navigation';
import { supabase } from '../../supabaseClient';
import toast, { Toaster } from 'react-hot-toast';
import '../../styles/profile.css';

interface VoiceSettings {
  speaking_rate: number;
  pitch: number;
  volume: number;
  character: string;
  context: string;
  emotion: string;
  accent: string;
  gender: string;
  rhythm: number;
  emphasis: number;
  pause_duration: number;
  pronunciation_mode: string;
}

interface ContentPreferences {
  default_content_type: string;
  reading_level: string;
  technical_vocab: boolean;
  interests: string[];
  summarization_length: string;
}

interface FormData {
  displayName: string;
  email: string;
  language: string;
  notifications: boolean;
  bio: string;
  voiceSettings: VoiceSettings;
  contentPreferences: ContentPreferences;
}

interface ValidationErrors {
  displayName?: string;
  bio?: string;
  voiceSettings?: {
    speaking_rate?: string;
    pitch?: string;
    volume?: string;
  };
}

export function Profile() {
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [formData, setFormData] = useState<FormData>({
    displayName: user?.user_metadata?.name || '',
    email: user?.email || '',
    language: 'en',
    notifications: true,
    bio: '',
    voiceSettings: {
      speaking_rate: 1.0,
      pitch: 1.0,
      volume: 1.0,
      character: 'professional',
      context: 'business',
      emotion: 'neutral',
      accent: 'neutral',
      gender: 'neutral',
      rhythm: 1.0,
      emphasis: 1.0,
      pause_duration: 1.0,
      pronunciation_mode: 'standard'
    },
    contentPreferences: {
      default_content_type: 'articles',
      reading_level: 'intermediate',
      technical_vocab: true,
      interests: [],
      summarization_length: 'medium'
    }
  });

  // Load initial profile data
  useEffect(() => {
    const loadProfile = async () => {
      if (!user?.id) return;

      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', user.id)
          .single();

        if (error) throw error;

        if (data) {
          setFormData(prev => ({
            ...prev,
            displayName: data.display_name || prev.displayName,
            language: data.preferred_language || prev.language,
            notifications: data.notification_preferences?.email_notifications ?? prev.notifications,
            bio: data.bio || '',
            voiceSettings: data.voice_settings || prev.voiceSettings,
            contentPreferences: data.content_preferences || prev.contentPreferences
          }));
        }
      } catch (err) {
        console.error('Error loading profile:', err);
      }
    };

    loadProfile();
  }, [user?.id]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        [name]: (e.target as HTMLInputElement).checked
      }));
      return;
    }

    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      if (parent === 'voiceSettings' || parent === 'contentPreferences') {
        setFormData(prev => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: type === 'range' ? parseFloat(value) : value
          }
        }));
      }
      return;
    }

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validateForm = (): boolean => {
    const errors: ValidationErrors = {};

    // Validate display name
    if (!formData.displayName.trim()) {
      errors.displayName = 'Display name is required';
    } else if (formData.displayName.length < 2) {
      errors.displayName = 'Display name must be at least 2 characters';
    }

    // Validate bio length
    if (formData.bio && formData.bio.length > 500) {
      errors.bio = 'Bio must be less than 500 characters';
    }

    // Validate voice settings
    const voiceErrors: ValidationErrors['voiceSettings'] = {};
    if (formData.voiceSettings.speaking_rate < 0.5 || formData.voiceSettings.speaking_rate > 2) {
      voiceErrors.speaking_rate = 'Speaking rate must be between 0.5 and 2';
    }
    if (formData.voiceSettings.pitch < 0.5 || formData.voiceSettings.pitch > 2) {
      voiceErrors.pitch = 'Pitch must be between 0.5 and 2';
    }
    if (formData.voiceSettings.volume < 0 || formData.voiceSettings.volume > 1) {
      voiceErrors.volume = 'Volume must be between 0 and 1';
    }
    if (Object.keys(voiceErrors).length > 0) {
      errors.voiceSettings = voiceErrors;
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.id) {
      console.error('No user ID found');
      return;
    }

    // Validate form before submission
    if (!validateForm()) {
      toast.error('Please fix the form errors before submitting');
      return;
    }

    const updatePromise = (async () => {
      try {
        setLoading(true);
        setError(null);

        // Prepare profile data
        const profileData = {
          id: user.id,
          display_name: formData.displayName,
          preferred_language: formData.language,
          bio: formData.bio,
          voice_settings: formData.voiceSettings,
          content_preferences: formData.contentPreferences,
          notification_preferences: {
            email_notifications: formData.notifications,
            recording_reminders: true,
            processing_updates: true,
            marketing_updates: false
          },
          updated_at: new Date().toISOString()
        };

        console.log('Updating profile with data:', profileData);

        const { error: updateError } = await supabase
          .from('profiles')
          .upsert(profileData)
          .select()
          .single();

        if (updateError) {
          throw updateError;
        }

        // Update user metadata
        const { error: metadataError } = await supabase.auth.updateUser({
          data: { name: formData.displayName }
        });

        if (metadataError) {
          throw metadataError;
        }

        setIsEditing(false);
        return 'Profile updated successfully!';
      } catch (err) {
        console.error('Error in profile update:', err);
        throw new Error(err instanceof Error ? err.message : 'Failed to update profile');
      } finally {
        setLoading(false);
      }
    })();

    toast.promise(updatePromise, {
      loading: 'Updating profile...',
      success: (message) => message,
      error: (err) => err.message
    });
  };

  return (
    <div className="dashboard-layout">
      <Navigation />
      <div className="profile-container">
        <header className="profile-header">
          <h1>Profile Settings</h1>
          {error && <div className="error-message">{error}</div>}
        </header>

        <div className="profile-content">
          <form onSubmit={handleSubmit}>
            <div className="profile-section">
              <h2>Personal Information</h2>
              <div className="form-group">
                <label className="form-label">Display Name</label>
                <input
                  type="text"
                  name="displayName"
                  className={`form-input ${validationErrors.displayName ? 'error' : ''}`}
                  value={formData.displayName}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                  placeholder="Enter your display name"
                />
                {validationErrors.displayName && (
                  <div className="error-text">{validationErrors.displayName}</div>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-input"
                  value={formData.email}
                  disabled
                  placeholder="Your email address"
                />
              </div>
              <div className="form-group">
                <label className="form-label">Bio</label>
                <textarea
                  name="bio"
                  className={`form-input ${validationErrors.bio ? 'error' : ''}`}
                  value={formData.bio}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                  placeholder="Tell us about yourself"
                />
                {validationErrors.bio && (
                  <div className="error-text">{validationErrors.bio}</div>
                )}
                <div className="character-count">
                  {formData.bio.length}/500 characters
                </div>
              </div>
            </div>

            <div className="profile-section">
              <h2>Voice Settings</h2>
              <div className="form-group">
                <label className="form-label">Speaking Rate</label>
                <input
                  type="range"
                  name="voiceSettings.speaking_rate"
                  min="0.5"
                  max="2"
                  step="0.1"
                  value={formData.voiceSettings.speaking_rate}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                  className={validationErrors.voiceSettings?.speaking_rate ? 'error' : ''}
                />
                <span>{formData.voiceSettings.speaking_rate}x</span>
                {validationErrors.voiceSettings?.speaking_rate && (
                  <div className="error-text">{validationErrors.voiceSettings.speaking_rate}</div>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">Pitch</label>
                <input
                  type="range"
                  name="voiceSettings.pitch"
                  min="0.5"
                  max="2"
                  step="0.1"
                  value={formData.voiceSettings.pitch}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                  className={validationErrors.voiceSettings?.pitch ? 'error' : ''}
                />
                <span>{formData.voiceSettings.pitch}x</span>
                {validationErrors.voiceSettings?.pitch && (
                  <div className="error-text">{validationErrors.voiceSettings.pitch}</div>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">Volume</label>
                <input
                  type="range"
                  name="voiceSettings.volume"
                  min="0"
                  max="1"
                  step="0.1"
                  value={formData.voiceSettings.volume}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                  className={validationErrors.voiceSettings?.volume ? 'error' : ''}
                />
                <span>{Math.round(formData.voiceSettings.volume * 100)}%</span>
                {validationErrors.voiceSettings?.volume && (
                  <div className="error-text">{validationErrors.voiceSettings.volume}</div>
                )}
              </div>
            </div>

            <div className="profile-section">
              <h2>Voice Personality</h2>
              <div className="form-group">
                <label className="form-label">Voice Character</label>
                <select
                  name="voiceSettings.character"
                  className="form-input"
                  value={formData.voiceSettings.character}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                >
                  <option value="professional">Professional</option>
                  <option value="casual">Casual</option>
                  <option value="friendly">Friendly</option>
                  <option value="formal">Formal</option>
                </select>
              </div>

              <div className="form-group">
                <label className="form-label">Speaking Context</label>
                <select
                  name="voiceSettings.context"
                  className="form-input"
                  value={formData.voiceSettings.context}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                >
                  <option value="business">Business</option>
                  <option value="personal">Personal</option>
                  <option value="academic">Academic</option>
                  <option value="creative">Creative</option>
                </select>
              </div>

              <div className="form-group">
                <label className="form-label">Emotion/Tone</label>
                <select
                  name="voiceSettings.emotion"
                  className="form-input"
                  value={formData.voiceSettings.emotion}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                >
                  <option value="neutral">Neutral</option>
                  <option value="enthusiastic">Enthusiastic</option>
                  <option value="empathetic">Empathetic</option>
                  <option value="confident">Confident</option>
                </select>
              </div>

              <div className="form-group">
                <label className="form-label">Accent Preference</label>
                <select
                  name="voiceSettings.accent"
                  className="form-input"
                  value={formData.voiceSettings.accent}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                >
                  <option value="neutral">Neutral</option>
                  <option value="british">British</option>
                  <option value="american">American</option>
                  <option value="australian">Australian</option>
                </select>
              </div>
            </div>

            <div className="profile-section">
              <h2>Advanced Voice Controls</h2>
              <div className="form-group">
                <label className="form-label">Voice Gender</label>
                <select
                  name="voiceSettings.gender"
                  className="form-input"
                  value={formData.voiceSettings.gender}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                >
                  <option value="neutral">Neutral</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>

              <div className="form-group">
                <label className="form-label">Speech Rhythm</label>
                <input
                  type="range"
                  name="voiceSettings.rhythm"
                  min="0.5"
                  max="1.5"
                  step="0.1"
                  value={formData.voiceSettings.rhythm}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                />
                <span>{formData.voiceSettings.rhythm}x</span>
              </div>

              <div className="form-group">
                <label className="form-label">Word Emphasis</label>
                <input
                  type="range"
                  name="voiceSettings.emphasis"
                  min="0.5"
                  max="1.5"
                  step="0.1"
                  value={formData.voiceSettings.emphasis}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                />
                <span>{formData.voiceSettings.emphasis}x</span>
              </div>

              <div className="form-group">
                <label className="form-label">Pause Duration</label>
                <input
                  type="range"
                  name="voiceSettings.pause_duration"
                  min="0.5"
                  max="1.5"
                  step="0.1"
                  value={formData.voiceSettings.pause_duration}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                />
                <span>{formData.voiceSettings.pause_duration}x</span>
              </div>

              <div className="form-group">
                <label className="form-label">Pronunciation Mode</label>
                <select
                  name="voiceSettings.pronunciation_mode"
                  className="form-input"
                  value={formData.voiceSettings.pronunciation_mode}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                >
                  <option value="standard">Standard</option>
                  <option value="precise">Precise</option>
                  <option value="relaxed">Relaxed</option>
                </select>
              </div>
            </div>

            <div className="profile-section">
              <h2>Content Preferences</h2>
              <div className="form-group">
                <label className="form-label">Default Content Type</label>
                <select
                  name="contentPreferences.default_content_type"
                  className="form-input"
                  value={formData.contentPreferences.default_content_type}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                >
                  <option value="articles">Articles</option>
                  <option value="social">Social Media</option>
                  <option value="documents">Documents</option>
                  <option value="books">Books</option>
                </select>
              </div>

              <div className="form-group">
                <label className="form-label">Reading Level</label>
                <select
                  name="contentPreferences.reading_level"
                  className="form-input"
                  value={formData.contentPreferences.reading_level}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                >
                  <option value="beginner">Beginner</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="advanced">Advanced</option>
                  <option value="expert">Expert</option>
                </select>
              </div>

              <div className="form-group">
                <label className="form-label">
                  <input
                    type="checkbox"
                    name="contentPreferences.technical_vocab"
                    checked={formData.contentPreferences.technical_vocab}
                    onChange={handleInputChange}
                    disabled={!isEditing || loading}
                  />
                  {' '}Include Technical Vocabulary
                </label>
              </div>

              <div className="form-group">
                <label className="form-label">Summarization Length</label>
                <select
                  name="contentPreferences.summarization_length"
                  className="form-input"
                  value={formData.contentPreferences.summarization_length}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                >
                  <option value="short">Short</option>
                  <option value="medium">Medium</option>
                  <option value="long">Long</option>
                  <option value="detailed">Detailed</option>
                </select>
              </div>

              <div className="form-group">
                <label className="form-label">Interests</label>
                <div className="interests-container">
                  {['Technology', 'Business', 'Science', 'Arts', 'Health'].map((interest) => (
                    <label key={interest} className="interest-checkbox">
                      <input
                        type="checkbox"
                        checked={formData.contentPreferences.interests.includes(interest.toLowerCase())}
                        onChange={(e) => {
                          const interests = e.target.checked
                            ? [...formData.contentPreferences.interests, interest.toLowerCase()]
                            : formData.contentPreferences.interests.filter(i => i !== interest.toLowerCase());
                          setFormData(prev => ({
                            ...prev,
                            contentPreferences: {
                              ...prev.contentPreferences,
                              interests
                            }
                          }));
                        }}
                        disabled={!isEditing || loading}
                      />
                      {interest}
                    </label>
                  ))}
                </div>
              </div>
            </div>

            <div className="profile-section">
              <h2>Preferences</h2>
              <div className="form-group">
                <label className="form-label">Language</label>
                <select
                  name="language"
                  className="form-input"
                  value={formData.language}
                  onChange={handleInputChange}
                  disabled={!isEditing || loading}
                >
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                  <option value="fr">French</option>
                </select>
              </div>
              <div className="form-group">
                <label className="form-label">
                  <input
                    type="checkbox"
                    name="notifications"
                    checked={formData.notifications}
                    onChange={handleInputChange}
                    disabled={!isEditing || loading}
                  />
                  {' '}Enable Notifications
                </label>
              </div>
            </div>

            <div className="profile-section">
              {!isEditing ? (
                <button
                  type="button"
                  className="edit-button"
                  onClick={() => setIsEditing(true)}
                >
                  Edit Profile
                </button>
              ) : (
                <div className="button-group">
                  <button
                    type="submit"
                    className="save-button"
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="loading-spinner">
                        <span className="spinner"></span>
                        Saving...
                      </span>
                    ) : (
                      'Save Changes'
                    )}
                  </button>
                  <button
                    type="button"
                    className="cancel-button"
                    onClick={() => {
                      setIsEditing(false);
                      setValidationErrors({});
                    }}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <Toaster position="top-right" />
    </div>
  );
}
