import React from 'react';
import '../styles/hamburger.css';

interface HamburgerIconProps {
  isOpen: boolean;
  onClick: () => void;
}

export function HamburgerIcon({ isOpen, onClick }: HamburgerIconProps) {
  return (
    <button 
      className={`hamburger ${isOpen ? 'open' : ''}`}
      onClick={onClick}
      aria-label={isOpen ? 'Close menu' : 'Open menu'}
      aria-expanded={isOpen}
    >
      <span className="hamburger-line"></span>
      <span className="hamburger-line"></span>
      <span className="hamburger-line"></span>
    </button>
  );
}
