import React, { useState, useRef, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import toast from 'react-hot-toast';

interface Recording {
  name: string;
  url: string;
  filename: string;
  timestamp: number;
}

export default function VoiceRecorder() {
  const [isRecording, setIsRecording] = useState(false);
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);

  useEffect(() => {
    fetchRecordings();
  }, []);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        await saveRecording(audioBlob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
      toast.error('Failed to start recording');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
  };

  const saveRecording = async (audioBlob: Blob) => {
    try {
      setIsLoading(true);
      const filename = `recording_${Date.now()}.wav`;
      
      // Upload to Supabase
      const { error: uploadError } = await supabase.storage
        .from('recordings')
        .upload(filename, audioBlob);

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = await supabase.storage
        .from('recordings')
        .getPublicUrl(filename);

      // Add to state
      const newRecording = {
        name: filename,
        url: publicUrl,
        filename: filename,
        timestamp: Date.now()
      };

      setRecordings(prev => [newRecording, ...prev]);
      toast.success('Recording saved');
    } catch (error) {
      console.error('Error saving recording:', error);
      toast.error('Failed to save recording');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteRecording = async (filename: string) => {
    try {
      setIsLoading(true);
      console.log('Attempting to delete:', filename);

      // First verify the file exists
      const { data: files, error: listError } = await supabase.storage
        .from('recordings')
        .list();

      if (listError) {
        console.error('Error listing files:', listError);
        throw listError;
      }

      const fileExists = files?.some(file => file.name === filename);
      if (!fileExists) {
        console.error('File not found:', filename);
        throw new Error('File not found');
      }

      // Delete the file
      const { error: deleteError } = await supabase.storage
        .from('recordings')
        .remove([filename]);

      if (deleteError) {
        console.error('Delete error:', deleteError);
        throw deleteError;
      }

      // Double check it's gone
      const { data: checkFiles } = await supabase.storage
        .from('recordings')
        .list();

      const stillExists = checkFiles?.some(file => file.name === filename);
      if (stillExists) {
        console.error('File still exists after deletion');
        throw new Error('File deletion failed');
      }

      // Update local state
      setRecordings(prev => prev.filter(rec => rec.filename !== filename));
      toast.success('Recording deleted');

      // Refresh the list
      await fetchRecordings();
    } catch (error: any) {
      console.error('Error in deleteRecording:', error);
      toast.error(error.message || 'Failed to delete recording');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRecordings = async () => {
    try {
      setIsLoading(true);
      
      const { data: files, error } = await supabase.storage
        .from('recordings')
        .list();

      if (error) throw error;

      if (!files) {
        setRecordings([]);
        return;
      }

      const recordingsWithUrls = await Promise.all(
        files.map(async (file) => {
          const { data: { publicUrl } } = await supabase.storage
            .from('recordings')
            .getPublicUrl(file.name);

          return {
            name: file.name,
            url: publicUrl,
            filename: file.name,
            timestamp: file.created_at ? new Date(file.created_at).getTime() : Date.now()
          };
        })
      );

      setRecordings(recordingsWithUrls.sort((a, b) => b.timestamp - a.timestamp));
    } catch (error) {
      console.error('Error fetching recordings:', error);
      toast.error('Failed to fetch recordings');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <button
          onClick={isRecording ? stopRecording : startRecording}
          className={`px-4 py-2 rounded ${
            isRecording 
              ? 'bg-red-500 hover:bg-red-600' 
              : 'bg-blue-500 hover:bg-blue-600'
          } text-white font-semibold`}
          disabled={isLoading}
        >
          {isRecording ? 'Stop Recording' : 'Start Recording'}
        </button>
      </div>

      <div className="space-y-4">
        {recordings.map((recording) => (
          <div 
            key={recording.filename}
            className="p-4 border rounded-lg shadow flex items-center justify-between"
          >
            <div>
              <audio controls src={recording.url} className="mb-2" />
            </div>
            <button
              onClick={() => deleteRecording(recording.filename)}
              className="px-3 py-1 bg-red-500 hover:bg-red-600 text-white rounded"
              disabled={isLoading}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
