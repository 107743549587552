import { Auth as SupabaseAuth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';

export function Auth() {
  const navigate = useNavigate();
  const { user, session } = useAuth();

  useEffect(() => {
    // Only redirect if we have both user AND valid session
    if (user && session?.access_token) {
      const validateAndRedirect = async () => {
        try {
          // Verify the session is still valid
          const { data: { session: currentSession }, error } = await supabase.auth.getSession();
          if (error || !currentSession) {
            console.log('Invalid session detected');
            return;
          }
          navigate('/dashboard');
        } catch (error) {
          console.error('Session validation error:', error);
        }
      };
      validateAndRedirect();
    }
  }, [user, session, navigate]);

  // Get the current site URL dynamically
  const siteUrl = window.location.origin;

  return (
    <div className="auth-container">
      <h1>Welcome to VoiceFlow</h1>
      <SupabaseAuth 
        supabaseClient={supabase}
        appearance={{ 
          theme: ThemeSupa,
          variables: {
            default: {
              colors: {
                brand: '#404040',
                brandAccent: '#2d2d2d'
              }
            }
          }
        }}
        providers={[]}
        redirectTo={`${siteUrl}/reset-password`}
        onlyThirdPartyProviders={false}
      />
    </div>
  );
}
