import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';

interface AuthRedirectProps {
  children: React.ReactNode;
  redirectTo: string;
}

export function AuthRedirect({ children, redirectTo }: AuthRedirectProps) {
  const { user, session } = useAuth();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  
  useEffect(() => {
    const validateSession = async () => {
      if (user && session?.access_token) {
        try {
          const { data: { session: currentSession }, error } = await supabase.auth.getSession();
          if (!error && currentSession?.access_token === session.access_token) {
            setShouldRedirect(true);
          }
        } catch (error) {
          console.error('Session validation error:', error);
        }
      }
    };
    validateSession();
  }, [user, session]);

  if (shouldRedirect) {
    return <Navigate to={redirectTo} replace />;
  }

  return <>{children}</>;
}
